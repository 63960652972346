import React from 'react';
import { Stat } from './Stat'
import { formatSeconds } from "../functions/formatSeconds";
import { getPercentageClass } from "../functions/getPercentageClass";
import { Segment } from 'semantic-ui-react'
import { Stats } from './Stats'
import './UserInfo.css';

export function UserInfo(props) {
    const { seconds_spent, seconds_available, u1, u2, seconds_away, productiveHours } = props.stats
    const spent = formatSeconds(seconds_spent)
    const avail = formatSeconds(seconds_available)
    const away = formatSeconds(seconds_away)
    const prod = formatSeconds(productiveHours)
    const percentage = (seconds_available > 0) ? Math.round((seconds_spent / seconds_available) * 100) : "n/a";
    const percentageUnit = (seconds_available > 0) ? '%' : "";
    const className = getPercentageClass(percentage);
    return (
        <Segment className={className}>
            <Stats>
                <Stat major="Gebucht" minor="in Zeitraum" value={spent.time} unit={spent.unit} icon="book" />
                <Stat major="Produktiv" minor="in Zeitraum" value={prod.time} unit={prod.unit} icon="book" />
                <Stat major="Verfügbar" minor="in Zeitraum" value={avail.time} unit={avail.unit} icon="calendar outline" />
                <Stat major="Abwesend" minor="in Zeitraum" value={away.time} unit={away.unit} icon="calendar outline" />
                <Stat major="U1" minor="in Zeitraum" value={u1 * 100} unit={""} icon="percent" />
                <Stat major="U2" minor="in Zeitraum" value={u2 * 100} unit={""} icon="percent" />
            </Stats>
        </Segment>
    )
}
